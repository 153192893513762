import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

import moviebot from "../assets/img/moviebot.png";
import weatherapp from "../assets/img/weatherapp.png";
import simplechat from "../assets/img/simplechat.png";
import timetable from "../assets/img/timetable.png";

import twinprime from "../assets/img/twinprime.png";
import eqfinder from "../assets/img/eqfinder.png";
import edgify from "../assets/img/edgify.png";


import basicbot from "../assets/img/basicbot.png";
import rebound from "../assets/img/rebound.png";



export const Projects = () => {

  const projects = [ 
    {
      title: "MovieBot",
      description: "A Discord Bot which uses the OMDb API to fetch data from IMDb.",
      imgUrl: moviebot,
      url: "https://github.com/mjmiah/MovieBot",
    },
    {
      title: "WeatherApp",
      description: "A website which uses the OpenWeather API to display the weather forecast.",
      imgUrl: weatherapp,
      url: "https://github.com/mjmiah/WeatherApp",
    },
    {
      title: "Simple Chat",
      description: "A chat room written with Flask, Socket.io.",
      imgUrl: simplechat,
      url: "https://github.com/mjmiah/Simple-Chat",
    },
    {
      title: "Timetable System",
      description: "A school timetabling system written in Visual Basic.",
      imgUrl: timetable,
      url: "https://github.com/mjmiah/TimetableSystem",
    },
  ];  

  const maths = [ 
    {
      title: "Edgify",
      description: "An image edge detector using the Laplacian of Gaussian filter.",
      imgUrl: edgify,
      url: "https://github.com/mjmiah/Edgify",
    },
    {
      title: "Twin Prime Conjecture VIsualiser",
      description: "A visual display of the twin prime conjecture using Pandas and Streamlit.",
      imgUrl: twinprime,
      url: "https://github.com/mjmiah/Twin-Prime-Visualiser",
    },
    {
      title: "Equation Finder",
      description: "A program witten in Visual Basic which forms a polynomial equation given its roots.",
      imgUrl: eqfinder,
      url: "https://github.com/mjmiah/Equation-Finder",
    },
  ];  

  const other = [ 
    {
      title: "BasicBot",
      description: "A ChatBot written in JavaScript which evaluates basic calculations.",
      imgUrl: basicbot,
      url: "https://github.com/mjmiah/BasicBot",
    },
    {
      title: "Rebound",
      description: "A 3D Game made in Unreal Engine.",
      imgUrl: rebound,
      url: "https://github.com/mjmiah/Rebound",
    },
  ];  

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>Here are some coding projects I've worked on.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Backend</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Maths</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Others</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                                <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                          {
                            maths.map((project, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...project}
                                  />
                              )
                            })
                          }
                        </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                        {
                          other.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
